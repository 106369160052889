export default {
  contList01 : [
    {tit: '구조설계 기술 개발', txt: '건설 구조물의 기능과 <br>경제성을 향상시킬 수 있는<br>설계 기술을 개발합니다.'},
    {tit: '구조 성능 평가', txt: '건설 제품의 용도에 맞는<br>성능 및 안정성을 검증합니다.'},
    {tit: '기술 인증', txt: '혁신적인 기술을 통해 개발된<br>건설 제품의 기술 인증을<br>추진합니다.'},
    {tit: '대안 설계', txt: '건설 제품의 원가 절감과<br> 제품의 가치를 높이기 위한<br> 최적 솔루션을 제공합니다.'},
  ],
  contList02 : [
    {tit: '고객사 연계 제품 홍보', txt: '건설 제품의 사업화를 위해<br> 제품과 고객사의 홍보 효과를<br> 극대화 시킵니다.'},
    {tit: '기술 영업 지원', txt: '고객 만족을 위한 <br>현대제철의 건설 전문 역량을 <br>제공합니다.'},
    {tit: 'BIM 지원', txt: '구조화된 3차원 모델 <br>데이터를 제공합니다.'},
    {tit: 'HCORE STORE 연계', txt: '고성능 강재를 쉽고 빠르게 <br>구매할 수 있는 전자상거래 <br>시스템을 제공합니다.'},
  ],
  contList03: [
    {
      num:1,
      tit:'지원서 접수',
      subTit01: '지원대상',
      subTit02 :'공모분야',
      subTit03: '지원기간',
      subTxt01:':시공사, 철구제작사, 구조설계사 <br>: 중소기업 / 스타트업 기업 <br>: 건설 관련 산학연 컨소시움 가능',
      subTxt02:': 친환경 • 저탄소,  스마트 건설, 안전 • 안심',
      subTxt03:': H CORE SQUARE 홈페이지 상시 접수'
    },
    {
      num:2,
      tit:'방문심사',
      subTit01: '방문심사 기준',
      subTit02 :'심사기간',
      subTxt01:': 시장성 / 사업성 / 기술성 / ESG관점 심사',
      subTxt02:': 2~4주'
    },
    {
      num:3,
      tit:'지원서 접수',
      subTit01: '서류심사 기준',
      subTit02 :'심사기간',
      subTxt01:': 최종 제품 개발을 통한 실용화 연구 수행 역량 확인 <br> (공장 설비, 시공능력, 전문 지식 등)',
      subTxt02:': 2주'
    },
    {
      num:4,
      tit:'과제 착수',
      subTit01: '과제계약',
      subTit02 :'지식재산권 소유권',
      subTxt01:': 연구비 / 과제 기간 / 최종 성과품 결정 <br> : 사업화 및 시장 진출 방향 도출',
      subTxt02:': : 공동 지식재산권 확보를 기본<br>: 과제 성격에 따라 협의 가능'
    },
    {
      num:5,
      tit:'연구 개발',
      subTit01: '주요 연구내용',
      subTit02 :'연구성과 활용 방안 수립',
      subTxt01:': 제품 개발 ~ 시용과 관련 세부 연구 내용 도출<br>: 현대제철 & 고객사 연구 개발 범위 설정',
      subTxt02:': 관련 성과품 Supply chain 분석 <br>: 연구성과 시장 진출을 위한 마케팅 전략 수립'
    },
    {
      num:6,
      tit:'제품 개발 완료',
      subTit01: '최종 보고회',
      subTit02 :'후속연구 과제 검토',
      subTxt01:': 연구개발 결과에 대한 최종 보고 및 보고서 작성',
      subTxt02:': 계속 지원을 통한 사업성 /  ESG 경영 이점'
    }
  ]
}




